<template>
  <CustomDialog
    :open.sync="open"
    :title="title"
    :max-width="1024"
    :has-button2="false"
    button1-text="Close"
    @button1="close_dialog"
  >
    <template v-slot:content>
      <v-card flat v-if="item">
        <v-card-text>
          <v-row dense class="fill-height">
            <v-col md="8" sm="12" class="pr-2 py-0" align="center">
              <template v-if="item.props && item.props.canIframe">
                <iframe
                  :src="item.url"
                  class="iframe-vue"
                  @load="iframe_is_loaded = true"
                  v-show="iframe_is_loaded"
                />
                <v-progress-linear
                  class="py-4"
                  v-if="!iframe_is_loaded"
                  indeterminate
                  color="primary"
                >
                  <template v-slot:default>
                    <strong>Loading contents... Please wait...</strong>
                  </template>
                </v-progress-linear>
              </template>
              <img
                class="mx-auto"
                style="max-width: 100%"
                v-else-if="item.props && item.props.image"
                v-lazy="item.props.image"
              />
              <Empty v-else headline="No preview" />
            </v-col>
            <v-col
              md="4"
              sm="12"
              class="border-1 rounded-10 border-color--grey"
            >
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Published URL
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <a :href="item.url" target="_blank">
                        {{ item.title || item.url }}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Domain Authority
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.props.domain_authority || 'Not Set' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Date Created
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.created_at | format('MMM DD YYYY hh:mm A') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Status
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.status | ucwords }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.site">
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Backlink Site
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip outlined x-large class="border-0 fullwidth">
                        <v-avatar class="mr-1" tile v-if="item.site.icon">
                          <v-img
                            :lazy-src="settings.loader"
                            contain
                            :src="item.site.icon"
                          ></v-img>
                        </v-avatar>
                        <a :href="item.site.url" target="_blank">
                          {{ item.site.name }}
                        </a>
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.strategy">
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Strategy
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.strategy.name | ucwords }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.tags">
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Tags
                    </v-list-item-title>
                    <v-list-item-content>
                      <template v-for="(tag, index) in item.tags">
                        <v-chip class="mr-1 mb-1" label :key="index" outlined>
                          {{ tag }}
                        </v-chip>
                      </template>
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fullwidth">
                      Attachments
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      @click="$refs.upload.$refs.input.click()"
                      text
                      small
                      outlined
                      class="text-none"
                    >
                      <v-icon small left>mdi-plus-circle-outline</v-icon>
                      Add
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-divider v-if="item.attachments.length" />
              <v-row>
                <v-col
                  md="6"
                  sm="12"
                  v-for="(attachment, index) in item.attachments"
                  :key="attachment.uuid"
                >
                  <div class="float-icon">
                    <v-btn
                      @click="deleteAttachment(attachment, index)"
                      class="mr-1"
                      color="red"
                      icon
                    >
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isImageOrVideo(attachment)"
                      @click="viewAttachment(attachment)"
                      class="mr-1"
                      color="success"
                      icon
                    >
                      <v-icon>mdi-eye-circle</v-icon>
                    </v-btn>
                    <v-btn
                      class="mr-1"
                      :href="attachment.url"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                  </div>
                  <Attachment
                    :styles="`max-height: 250px;`"
                    :classes="`mx-auto`"
                    :item="attachment"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-file-input
          ref="upload"
          @change="handleUploads($event, autoSaveAttachment)"
          style="display: none"
          multiple
        />
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { seo_mixin } from '../SEO-Backlinks/seo_mixin'
import request from '@/services/axios_instance'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'BackLinkViewDialog',
  mixins: [seo_mixin],
  components: {
    CustomDialog
  },
  props: {
    backlink: Object,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    item: null,
    iframe_is_loaded: false
  }),
  watch: {
    dialog(val) {
      this.open = val
      this.iframe_is_loaded = false
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    backlink: {
      handler(val) {
        this.item = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    title() {
      return this.item ? this.item.title : 'View Backlink'
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    },
    autoSaveAttachment(data) {
      request
        .post(`api/seo/backlinks/${this.item.id}/attachments`, data)
        .then(({ data }) => {
          this.item.attachments.push(data)
          this.$emit('new-attachment', data)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.iframe-vue {
  width: 100%;
  height: 100vh;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

.float-icon {
  position: absolute;
  z-index: 2;
}
</style>
