<template>
  <v-card
    elevation="0"
    color="transparent"
    outlined
    class="mt-0 rounded-10"
    min-height="400"
  >
    <v-row dense class="fill-height" justify="center" align="center">
      <v-col :cols="has_more_items ? 11 : 12" class="px-2">
        <v-sheet class="py-1 px-0" color="transparent">
          <v-chip-group show-arrows mandatory v-model="activeItem">
            <template v-if="items.length">
              <v-chip
                label
                style="
                  height: 40px !important;
                  box-shadow: 0px 0px 2px 0px #a6a5a5a8 !important;
                "
                color="white"
                class="caption font-weight-bold"
                :value="report"
                active-class="primary light--text"
                v-for="report in items"
                :key="report.id"
              >
                {{ report.title | ucwords | trunc_mid(15, 15) }}
              </v-chip>
            </template>
            <AddReportMenu
              @new-via-link="add_edit_report_via_url(true, null)"
              @new-via-template="add_edit_report_via_template(true, null)"
              @new-via-preset="add_edit_report_via_preset(true, null)"
              v-else
            ></AddReportMenu>
          </v-chip-group>
        </v-sheet>
      </v-col>
      <v-col cols="1" v-if="has_more_items">
        <v-btn
          color="primary"
          text
          class="text-none px-3"
          @click.stop="loadMoreItems"
        >
          <v-icon left>mdi-skip-next-circle</v-icon> More
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat outlined class="rounded-10">
      <v-card-title>
        <v-row dense align="center" justify="space-around">
          <PresetMenu
            :presets="preset_reports"
            @new-preset="create_preset_dialog = true"
          ></PresetMenu>
          <AddReportMenu
            @new-via-link="add_edit_report_via_url(true, null)"
            @new-via-template="add_edit_report_via_template(true, null)"
            @new-via-preset="add_edit_report_via_preset(true, null)"
          ></AddReportMenu>
          <v-spacer v-if="activeItem" />
          <v-sheet
            v-if="activeItem"
            class="title primary--text font-weight-bold text-center"
          >
            <!-- <v-avatar tile size="25" class="mr-2">
            <v-img  :lazy-src="settings.loader" contain :src="activeItem.icon"></v-img>
          </v-avatar> -->
            {{ activeItem.title | ucwords }}
          </v-sheet>
          <v-spacer />
          <v-sheet v-if="activeItem" class="pr-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  tile
                  color="primary"
                  v-bind="attrs"
                  width="30"
                  height="30"
                  v-on="on"
                  min-width="auto"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                  "
                  @click="open_icon_dialog(true, activeItem)"
                >
                  <v-icon size="17px"> mdi-image-plus </v-icon>
                </v-btn>
              </template>
              <span>Change Icon</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  tile
                  color="primary"
                  width="30"
                  min-width="auto"
                  height="30"
                  v-bind="attrs"
                  v-on="on"
                  @click="edit_report(activeItem)"
                >
                  <v-icon size="17px"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Edit Report</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  tile
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  min-width="auto"
                  style="
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                  "
                  width="30"
                  height="30"
                  @click="confirm_dialog = true"
                >
                  <v-icon size="17px"> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete Report</span>
            </v-tooltip>
          </v-sheet>
        </v-row>
      </v-card-title>
      <v-card-text class="" v-if="activeItem">
        <v-card outlined v-if="activeItem.url === 'template'">
          <ResponseView :items="activeItem.props.template"></ResponseView>
        </v-card>
        <IframeView
          v-else-if="activeItem.props && activeItem.props.canIframe"
          :iframe-src="activeItem.url"
        ></IframeView>
        <CrawlerView
          v-else-if="activeItem.props && activeItem.props.image"
          :active-report="activeItem"
        ></CrawlerView>
        <IframeView
          v-else-if="activeItem.url"
          :iframe-src="activeItem.url"
        ></IframeView>
        <Empty
          v-else
          icon="mdi-google-analytics"
          headline="Oops! Something went wrong. Cannot display report."
        ></Empty>
      </v-card-text>
      <v-card-text v-if="items.length === 0">
        <Empty
          icon="mdi-google-analytics"
          min-height="500"
          headline="No reports yet"
        ></Empty>
      </v-card-text>

      <DeleteDialog
        v-model="confirm_dialog"
        title="Delete Report"
        text-content="Are you sure you want to delete this report?"
        @delete="handleDeleteReport"
      ></DeleteDialog>

      <ReportURLDialog
        v-model="add_edit_via_url"
        :item="activeReport"
        :project-id="projectId"
        @save="handleSaveReportViaURL"
        @update="handleUpdateReportViaURL"
      ></ReportURLDialog>

      <ReportViaTemplate
        v-model="add_edit_via_template"
        :report="activeReport"
        :project-id="projectId"
        @save="handleSaveReportViaTemplate"
        @update="handleUpdateReportViaTemplate"
      ></ReportViaTemplate>

      <ReportViaPresets
        v-model="add_via_preset"
        :project-id="projectId"
        :presets.sync="preset_reports"
        @save="handleSaveReportViaPreset"
        @create-preset="create_preset_dialog = true"
      ></ReportViaPresets>

      <CreatePresetDialog
        v-model="create_preset_dialog"
        @save="handleSaveNewPreset"
      ></CreatePresetDialog>

      <ImageCropper
        @file="handleUpdateIcon"
        title="Upload Icon"
        width="100"
        height="100"
        v-model="icon_dialog"
        value-format="attachment"
      >
      </ImageCropper>
    </v-card>
  </v-card>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import { report_mixins } from '@/modules/ProjectPreview-Reports/Reports/report_mixins'
import { presets_mixin } from '@/modules/Reports/preset_mixins'
import AddReportMenu from '@/modules/Reports/components/AddReportMenu.vue'
import PresetMenu from '@/modules/Reports/components/PresetMenu.vue'
import IframeView from '@/modules/Reports/components/ReportsSection.vue'
import ResponseView from '@/modules/Forms-Builder/components/Builder/Response.vue'
import CrawlerView from '@/modules/Reports/components/CrawlerView.vue'
import ReportURLDialog from '@/modules/Reports/components/ReportURLDialog.vue'
import ReportViaTemplate from '@/modules/Reports/components/ReportViaTemplate.vue'
import CreatePresetDialog from '@/modules/Reports/components/CreatePresetDialog.vue'
import ReportViaPresets from '@/modules/Reports/components/ReportViaPresets.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
export default {
  mixins: [fetch_services, report_mixins, presets_mixin],
  components: {
    AddReportMenu,
    PresetMenu,
    IframeView,
    ResponseView,
    CrawlerView,
    DeleteDialog,
    ReportURLDialog,
    ReportViaTemplate,
    CreatePresetDialog,
    ReportViaPresets,
    ImageCropper
  },
  data: () => ({}),
  props: {
    projectId: [String, Number]
  },
  created() {
    this.loadItems(
      `api/projects/${this.projectId}/reports`,
      true,
      (data) => {
        this.activeItem = data.length ? data[0] : null
      },
      null
    )
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
