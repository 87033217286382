<template>
  <div class="files__tab">
    <v-card flat outlined class="seobacklinks-content">
      <VueTable
        :items="items"
        :headers="headers"
        :show-row-actions="true"
        title="Backlinks"
        :no-more-data="!has_more_items"
        :show-select="false"
        :has-footer="true"
        :loading="loading_items || loading_more_items"
        @load-more="loadMoreItems"
      >
        <template slot="header-toolbar">
          <v-btn-toggle multiple class="mr-1" dense>
            <v-menu left bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="text-none"
                  min-width="100"
                >
                  Month: {{ getSelectedMonth | ucwords }}
                  <v-icon right>mdi-menu-swap</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="filter.month = 'all'">
                  <v-list-item-title>All</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="month in monthsArray"
                  @click="filter.month = month.value"
                  :key="month.text"
                >
                  <v-list-item-title>{{ month.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu left bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="text-none"
                  min-width="100"
                >
                  Year: {{ filter.year | ucwords }}
                  <v-icon right>mdi-menu-swap</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="filter.year = 'all'">
                  <v-list-item-title>All</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="year in years"
                  :key="year"
                  @click="filter.year = year"
                >
                  <v-list-item-title>{{ year }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu left bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="text-none"
                  min-width="100"
                >
                  Status: {{ filter.status | ucwords }}
                  <v-icon right>mdi-menu-swap</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="filter.status = 'all'">
                  <v-list-item-title>All</v-list-item-title>
                </v-list-item>
                <v-list-item @click="filter.status = 'under_review'">
                  <v-list-item-title>Under Review</v-list-item-title>
                </v-list-item>
                <v-list-item @click="filter.status = 'published'">
                  <v-list-item-title>Published</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn-toggle>
          <v-btn
            text
            outlined
            class="text-none"
            @click="back_link_control(true, null)"
          >
            New Backlink
            <v-icon right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:row-slot="{ item }">
          <td class="text-cap">
            {{ item.created_at | format('MMM DD YYYY hh:mm A') }}
          </td>
          <td>
            <v-chip
              class="fullwidth caption px-2"
              :color="item.status === 'under_review' ? 'error' : 'success'"
              label
            >
              {{ item.status | ucwords }}
            </v-chip>
          </td>
          <td>
            <a
              :href="item.url"
              :title="item.url"
              style="max-width: 100%"
              target="_blank"
            >
              {{ item.url | truncate(60) }}
            </a>
          </td>
          <td>
            <v-chip outlined class="border-0" v-if="item.site">
              <v-avatar class="mr-1" tile v-if="item.site.icon">
                <v-img contain :src="item.site.icon"></v-img>
              </v-avatar>
              <a :href="item.site.url" target="_blank">{{ item.site.name }}</a>
            </v-chip>
          </td>
          <td>{{ item.strategy ? item.strategy.name : '' }}</td>
          <td>{{ item.domain_authority }}</td>
          <ActionDropdown
            :item="item"
            :has-view="true"
            :has-edit="true"
            :has-delete="false"
            :permissions="$_permissions.get('projects')"
            @delete="open_delete_dialog(item)"
            @edit="back_link_control(true, item)"
            @view="openViewer(item)"
          >
            <template v-slot:extra>
              <v-list-item
                v-if="item.status === 'published'"
                @click="handleUpdateStatus(item, 'under_review')"
              >
                <v-list-item-title class="primary--text font-weight-bold">
                  <v-icon color="primary" left>mdi-notebook</v-icon>
                  Set to Under Review
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                @click="handleUpdateStatus(item, 'published')"
              >
                <v-list-item-title class="primary--text font-weight-bold">
                  <v-icon color="primary" left>mdi-notebook-outline</v-icon>
                  Set to Published
                </v-list-item-title>
              </v-list-item>
            </template>
          </ActionDropdown>
        </template>
      </VueTable>
    </v-card>
    <BackLinkDialog
      :strategies="strategy_list"
      :sites="site_list"
      :item="activeItem"
      :dialog.sync="backlink_dialog"
      @save="handleSaveBacklink"
      @update="handleUpdateBacklink"
    />
    <BackLinkViewDialog
      :backlink="activeItem"
      :dialog.sync="backlink_view_dialog"
    />
  </div>
</template>
<style lang="scss" scoped>
@import '~@/sass/variables';

.files__tab {
  padding: 1.25rem;

  .seobacklinks-content {
    border-radius: 10px !important;
  }

  .page-title .v-icon {
    color: $textDark;
  }
}
</style>
<script>
import BackLinkDialog from '../components/BackLinkDialog.vue'
import BackLinkViewDialog from '../components/BackLinkViewDialog.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'

import { seo_mixin } from './seo_mixin'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'

export default {
  name: 'SEOBacklink',
  components: {
    BackLinkViewDialog,
    ActionDropdown,
    VueTable,
    BackLinkDialog
  },
  mixins: [seo_mixin, fetch_services],
  props: {
    projectId: [Number, String]
  },
  data: () => ({
    filter: {
      status: 'all',
      month: 'all',
      year: 'all'
    },
    backlink_dialog: false,
    backlink_view_dialog: false,
    headers: [
      { text: 'Created', value: 'created_at', width: 120 },
      { text: 'Status', value: 'status', width: 100 },
      { text: 'Publish URL', value: 'url', width: '20%' },
      { text: 'Backlinking Site', value: 'site.name', width: 200 },
      { text: 'Strategy', value: 'strategy.name', width: 130 },
      { text: 'DA', value: 'domain_authority', width: 80 },
      {
        text: 'Action',
        width: 40,
        value: 'action',
        sortable: false,
        align: 'center'
      }
    ],
    monthsArray: [
      { text: 'January', value: '01' },
      { text: 'February', value: '02' },
      { text: 'March', value: '03' },
      { text: 'April', value: '04' },
      { text: 'May', value: '05' },
      { text: 'June', value: '06' },
      { text: 'July', value: '07' },
      { text: 'August', value: '08' },
      { text: 'September', value: '09' },
      { text: 'October', value: '10' },
      { text: 'November', value: '11' },
      { text: 'December', value: '12' }
    ]
  }),
  computed: {
    getSelectedMonth() {
      let index = this.monthsArray.findIndex(
        (m) => m.value === this.filter.month
      )
      if (~index) return this.monthsArray[index].text
      return 'All'
    },
    years() {
      const now = new Date().getUTCFullYear()
      return Array(now - (now - 5))
        .fill('')
        .map((v, idx) => now - idx)
    },
    type() {
      return this.$route.params.type || 'project'
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        {
          text: this.type,
          disabled: false,
          route: { path: `/dashboard/${this.type}/preview/${this.projectId}` }
        },
        { text: 'SEO-Backlink', disabled: true, route: null }
      ]
    }
  },
  created() {
    this.getStrategies()
    this.getSites()
    this.getBacklinksFiles()
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  watch: {
    filter: {
      handler(val) {
        this.getBacklinksFiles()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    back_link_control(open, item) {
      this.activeItem = item
      this.backlink_dialog = open
    },
    getBacklinksFiles() {
      this.loadItems(
        `api/seo/${this.projectId}/backlinks/${this.type}?status=${this.filter.status}&month=${this.filter.month}&year=${this.filter.year}`
      )
    },
    openViewer(item) {
      this.activeItem = item
      this.backlink_view_dialog = true
    },
    callback() {
      this.back_link_control(false, null)
      this.$store.commit('set_loader', false)
    },
    handleSaveBacklink(payload) {
      this.$store.commit('set_loader', true)
      this.addItems(
        `api/seo/${this.projectId}/backlinks/${this.type}/bulk`,
        payload,
        'Backlink added!',
        null,
        this.callback
      )
    },
    handleUpdateBacklink(payload) {
      this.updateItem(
        `api/seo/backlinks/${payload.id}`,
        payload,
        'Backlink updated',
        null,
        this.back_link_control(false, null)
      )
    },
    handleUpdateStatus(item, status) {
      request
        .put(`api/seo/backlinks/${item.id}/status`, { status: status })
        .then(({ data }) => {
          this.replaceItem(data, null, null)
        })
    }
  }
}
</script>
