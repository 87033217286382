<template>
  <CustomDialog
    :open.sync="open"
    :title="title"
    @button1="closeDialog"
    button1-text="Cancel"
    :button2-text="btn_label"
    @button2="save_report"
    max-width="900"
    :main-btn-disabled="isDisabled"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <label class="primary--text subtitle-2"> Report Title * </label>
              <v-text-field
                dense
                :rules="requiredRules"
                outlined
                hide-details="auto"
                prepend-inner-icon="text_fields"
                v-model.trim="report_title"
              />
            </v-col>
            <v-col cols="6">
              <label class="primary--text subtitle-2">
                {{ select_template_label }}
              </label>
              <v-select
                :items="report_templates"
                dense
                :rules="requiredRules"
                outlined
                hide-details="auto"
                item-text="name"
                prepend-inner-icon="mdi-clipboard-outline"
                return-object
                v-model="report_template"
              >
                <template v-slot:no-data>
                  <v-alert class="mx-2" color="cyan" colored-border>
                    No report template yet.
                  </v-alert>
                </template>
                <template v-slot:append-outer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click.stop="
                          $router
                            .push({ name: 'builders-report--template' })
                            .catch(() => {})
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-plus-circle-outline
                      </v-icon>
                    </template>
                    <span>Create New Report Template</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- <FillUp :key="keyy" :structures="structures" v-model="structures" /> -->
          <v-sheet
            class="mt-3"
            v-for="(line, index) in structures"
            :key="`${keyy}-${index}`"
          >
            <QuestionSection
              v-if="line.tag === 'FormSection'"
              v-model="structures[index]"
            >
            </QuestionSection>
          </v-sheet>
          <Empty
            headline="Select a template or create one first"
            icon="mdi-clipboard-outline"
            v-if="!hasStructures"
          />
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import request from '@/services/axios_instance'
import _cloneDeep from 'lodash/cloneDeep'
//components
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
// import FillUp from '@/modules/Forms-Builder/components/Builder/FillUp.vue'
import QuestionSection from '@/modules/Forms-Online/components/QuestionSection.vue'
export default {
  name: 'ReportViaTemplate',
  components: {
    // FillUp,
    CustomDialog,
    QuestionSection
  },
  props: {
    title: { type: String, default: 'Add Report' },
    report: Object,
    projectId: [String, Number],
    value: { type: Boolean, default: false }
  },
  created() {
    this.getTemplates()
  },

  data: () => ({
    open: false,
    list: [],
    report_title: '',
    report_template: null,
    btnloading: false,
    keyy: 1,
    project_id: null,
    datus: []
  }),

  watch: {
    value(val) {
      this.open = val
    },
    report: {
      handler(val) {
        this.set_update_fields(val)
      },
      immediate: true,
      deep: true
    },
    open(val) {
      this.$emit('input', val)
      this.btnloading = false
    },
    report_template: {
      handler: function (val) {
        this.datus = val ? _cloneDeep(val.template) : []
      },
      deep: true,
      immediate: true
    },
    projectId: {
      handler: function (val) {
        this.project_id = val ? Number(val) : null
      },
      immediate: true
    }
  },
  computed: {
    isEdit() {
      return this.report ? true : false
    },
    btn_label() {
      return this.isEdit ? 'Update Report' : 'Save Report'
    },
    select_template_label() {
      return this.isEdit ? 'Select Report Template' : 'Select Report Template *'
    },
    report_templates() {
      return this.list.map((item) => {
        return { name: item.name, template: item.template, id: item.id }
      })
    },
    isDisabled() {
      return this.isValidFillIn() === false
    },
    structures: {
      get() {
        return this.datus || []
      },
      set(val) {
        this.datus = val
      }
    },
    hasStructures() {
      return this.structures.length > 0
    },
    payload() {
      let payload = {
        project_id: this.projectId || null,
        title: this.report_title,
        structures: this.structures,
        template: this.report_template ? this.report_template.id : null
      }
      if (this.isEdit) {
        payload.id = this.report.id
        if (!payload.template) {
          payload.template = this.report.props.template_id
        }
      }
      return payload
    }
  },
  methods: {
    set_update_fields(report) {
      if (report) {
        this.report_title = report.title
        this.datus = _cloneDeep(report.props.template)
        this.keyy += 1
      } else {
        this.report_title = null
        this.datus = []
      }
    },
    isValidFillIn() {
      if (this.structures.length) {
        let hasFind = this.structures.find((section) => {
          return section.value.find((i) => {
            if (
              i.hasOwnProperty('required') &&
              i.required &&
              (!i.value || i.value.length === 0)
            ) {
              return true
            } else return false
          })
        })
        return hasFind ? false : true
      } else return false
    },
    validate(withMessage) {
      if (!this.report_title) {
        if (withMessage) this.appSnackbar('Report title is required', 'error')
        return false
      }
      for (let i = 0; i < this.structures.length; i++) {
        if (
          this.structures[i].required &&
          (!this.structures[i].value || this.structures[i].value.trim() === '')
        ) {
          if (withMessage)
            this.appSnackbar('Fields with * are required', 'error')
          return false
        }
      }
      return true
    },
    closeDialog() {
      this.open = false
    },
    getTemplates() {
      request.get(`api/template/reports`).then(({ data }) => {
        this.list = data
      })
    },
    save_report() {
      if (!this.validate(true)) {
        return
      }
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    }
  }
}
</script>

<style scoped></style>
