<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="dialog_title"
    :button2-text="saveButtonLabel"
    @button2="save"
     
    :main-btn-disabled="!valid"
    :max-width="600"
  >
    <template v-slot:content>
      <v-form v-model="valid">
        <v-row dense class="custom-dialog">
          <v-col cols="12">
            <label class="primary--text subtitle-2" style="font-weight: 800;color: #223A6B;margin-bottom: 10px">Title</label>
            <v-text-field
              v-model="title"
              style="margin-top: 5px"
              outlined
              clearable
              dense
              :rules="requiredRules"
              class="add-report-input"
              clear-icon="mdi-close-circle-outline"
              hide-details="auto"
              prepend-inner-icon="text_fields"
              color="#657186"
            />
          </v-col>
          <v-col cols="12">
            <label class="primary--text subtitle-2" style="font-weight: 800;color: #223A6B;margin-bottom: 10px">URL</label>
            <v-text-field
              pattern="https://.*"
              type="url"
              v-model="link"
              prepend-inner-icon="link"
              class="add-report-input"
              clearable
              dense
              style="margin-top: 5px"
              clear-icon="mdi-close-circle-outline"
              :rules="[urlRule()]"
              outlined
              hide-details="auto"
              color="#657186"
            />
          </v-col>
        </v-row> 
      </v-form>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "ReportURLDialog",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    projectId: [String, Number],
    item: { type: Object },
  },
  computed: {
    isEdit() {
      return this.item ? true : false;
    },
    saveButtonLabel() {
      return this.isEdit ? "Update" : "Save";
    },
    dialog_title() {
      return this.isEdit ? "Update Report" : "New Report";
    },
    payload() {
      return {
        title: this.title,
        url: this.link,
        project_id: this.project_id,
      };
    },
  },
  data: () => ({
    dialog: false,
    title: null,
    link: null,
    valid: false,
    project_id: null,
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
    projectId: {
      handler: function (val) {
        this.project_id = val ? Number(val) : null;
      },
      immediate: true,
    },
    item: {
      handler: function (val) {
        this.title = val ? val.title : null;
        this.link = val ? val.url : null;
        if (val && val.project_id) {
          this.project_id = Number(val.project_id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() {
      if (this.valid) {
        this.$emit(this.isEdit ? "update" : "save", this.payload);
      }
    },
  },
};
</script>
 <style scoped>
 >>> .add-report-input .v-icon{
   color: #223A6B !important;
 }
 >>> .add-report-input input {
    padding-left: 10px;
    opacity: 0.5;
    font-weight: 700;
 }
</style>