<template>
  <CustomDialog
    :open.sync="open"
    :title="title"
    :max-width="editMode ? 600 : 1024"
    @button1="close_dialog"
    @button2="save"
    button2-text="Save"
    :main-btn-disabled="!can_save"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-row dense align="stretch" justify="center" class="fill-height">
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-form v-if="fileLink" v-model="validItem" ref="form">
                <v-row
                  dense
                  class="pa-2 border-1 border-color--grey rounded-10"
                >
                  <v-col cols="12">
                    <label class="primary--text subtitle-2">
                      Publish URL (Required)
                    </label>
                    <v-text-field
                      :disabled="form_disabled"
                      placeholder="Enter Publish URL"
                      v-model="fileLink.url"
                      dense
                      hide-details="auto"
                      outlined
                      clearable
                      clear-icon="mdi-close-circle-outline"
                      :rules="linkRules"
                    />
                  </v-col>
                  <v-col :cols="12">
                    <label class="primary--text subtitle-2">
                      Publish Title (Optional)
                    </label>
                    <v-text-field
                      :disabled="form_disabled"
                      placeholder="Enter Title"
                      v-model="fileLink.title"
                      dense
                      hide-details="auto"
                      outlined
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    />
                  </v-col>
                  <v-col :cols="12">
                    <label class="primary--text subtitle-2">
                      Publish tags (Optional)
                    </label>
                    <v-text-field
                      :disabled="form_disabled"
                      placeholder="Enter tags (Optional)"
                      hide-details="auto"
                      v-model="tag"
                      dense
                      outlined
                      clearable
                      class="mb-1"
                      clear-icon="mdi-close-circle-outline"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              :disabled="!tag || tag.length < 3"
                              @click="addTag"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-content-save-alert-outline
                            </v-icon>
                          </template>
                          <span>Insert tag</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col :cols="12" v-if="fileLink.tags.length">
                    <template v-for="(tag, index) in fileLink.tags">
                      <v-chip
                        class="mr-1 mb-1"
                        close
                        @click:close="removeTag(index)"
                        close-icon="mdi-close-circle-outline"
                        :key="index"
                        label
                        outlined
                      >
                        {{ tag }}
                      </v-chip>
                    </template>
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">
                      Domain Authority Score (Optional)
                    </label>
                    <v-text-field
                      :disabled="form_disabled"
                      placeholder="Enter Domain Authority Score"
                      type="number"
                      v-model.number="fileLink.domain_authority"
                      dense
                      outlined
                      hide-details="auto"
                      clearable
                      clear-icon="mdi-close-circle-outline"
                      aria-valuemax="100"
                      aria-valuemin="0"
                    />
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">
                      Backlink Status
                    </label>
                    <v-select
                      :disabled="form_disabled"
                      placeholder="Select Backlink Status"
                      v-model="fileLink.status"
                      :items="statuses"
                      clearable
                      item-value="value"
                      hide-details="auto"
                      dense
                      item-text="text"
                      outlined
                      clear-icon="mdi-close-circle-outline"
                    />
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">
                      Backlink Site (Optional)
                    </label>
                    <v-select
                      :disabled="form_disabled"
                      placeholder="Select Backlink Site"
                      v-model="fileLink.site_id"
                      :items="site_list"
                      item-value="id"
                      item-text="name"
                      clearable
                      dense
                      outlined
                      hide-details="auto"
                      clear-icon="mdi-close-circle-outline"
                    >
                      <template v-slot:append-outer>
                        <v-menu bottom left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-plus-circle-outline
                            </v-icon>
                          </template>
                          <v-card flat max-width="300">
                            <v-card-text class="text-right">
                              <v-form v-model="siteForm" ref="siteForm">
                                <v-text-field
                                  placeholder="Enter Strategy Name"
                                  label="Site Name (Required)"
                                  aria-required="true"
                                  v-model="site.name"
                                  dense
                                  outlined
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  :rules="nameRules"
                                  :disabled="submitting"
                                />
                                <v-text-field
                                  placeholder="https://"
                                  aria-required="true"
                                  label="Site URL (Required)"
                                  v-model="site.url"
                                  dense
                                  outlined
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  :rules="linkRules"
                                  :disabled="submitting"
                                />
                                <v-btn
                                  :disabled="!siteForm || submitting"
                                  @click="handleSaveSites"
                                  text
                                  outlined
                                  class="text-none"
                                  :loading="submitting"
                                >
                                  Save
                                </v-btn>
                              </v-form>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">
                      Strategy (Optional)
                    </label>
                    <v-select
                      :disabled="form_disabled"
                      placeholder="Select Strategy"
                      v-model="fileLink.strategy_id"
                      :items="strategy_list"
                      item-value="id"
                      outlined
                      item-text="name"
                      hide-details="auto"
                      dense
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    >
                      <template v-slot:append-outer>
                        <v-menu bottom left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-plus-circle-outline
                            </v-icon>
                          </template>
                          <v-card flat max-width="300">
                            <v-card-text class="text-right">
                              <v-form v-model="strategyForm" ref="strategyForm">
                                <v-text-field
                                  placeholder="Enter Strategy Name"
                                  label="Site Name (Required)"
                                  aria-required="true"
                                  v-model="strategy"
                                  dense
                                  outlined
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  :rules="nameRules"
                                  :disabled="submitting"
                                />
                                <v-btn
                                  :disabled="!strategyForm || submitting"
                                  @click="handleSaveStrategy"
                                  text
                                  outlined
                                  class="text-none"
                                  :loading="submitting"
                                >
                                  Save
                                </v-btn>
                              </v-form>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" v-if="!editMode">
                    <v-row dense class="my-2" align="center" justify="center">
                      <v-btn
                        color="primary"
                        class="text-none mx-2"
                        @click="clearAll"
                        :disabled="form_disabled"
                      >
                        Clear All
                        <v-icon right>mdi-delete-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="!can_add"
                        color="primary"
                        class="text-none mx-2"
                        @click="addItem"
                      >
                        Add
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-card flat class="fill-height">
                <v-card-text>
                  <h4
                    class="
                      font-weight-bold
                      text-right
                      primary--text
                      mb-2
                      subtitle-1
                    "
                  >
                    {{ items.length }} links, Max of {{ max_limit }}
                  </h4>

                  <template v-if="items.length">
                    <v-row
                      dense
                      class="
                        border-1
                        mb-1
                        rounded-10
                        fullwidth
                        border-color--grey
                      "
                      align="center"
                      justify="space-around"
                      v-for="(row, index) in items"
                      :key="index"
                    >
                      <v-col cols="1">
                        <v-avatar tile size="30">
                          <v-img
                            :lazy-src="settings.loader"
                            :src="getSrc(row.url)"
                          ></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="10">
                        <span> {{ row.url }} </span> <br />
                        <span class="caption">{{ row.title }}</span>
                      </v-col>
                      <v-col cols="1">
                        <v-icon @click="removeItem(index)">
                          mdi-close-circle-outline
                        </v-icon>
                      </v-col>
                    </v-row>
                  </template>
                  <Empty
                    v-else
                    headline="No backlink yet"
                    icon="mdi-arrow-left-bold-box"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { seo_mixin } from '../SEO-Backlinks/seo_mixin'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'BackLinkDialog',
  mixins: [seo_mixin],
  components: {
    CustomDialog
  },
  props: {
    item: Object,
    strategies: Array,
    sites: Array,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    items: [],
    btnloading: false,
    max_limit: 5,
    validItem: false,
    tag: null,
    fileLink: {
      id: null,
      url: null,
      title: null,
      tags: [],
      site_id: null,
      strategy_id: null,
      domain_authority: null,
      status: 'under_review'
    },
    statuses: [
      { value: 'under_review', text: 'Under Review' },
      { value: 'published', text: 'Published' }
    ]
  }),
  watch: {
    dialog(val) {
      this.open = val
      if (val) this.btnloading = false
      if (val && !this.item) {
        this.clearAll()
        this.items = []
      }
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    sites: {
      handler(val) {
        this.site_list = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    strategies: {
      handler(val) {
        if (val) this.strategy_list = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    item: {
      handler(val) {
        this.fileLink = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    can_add() {
      return this.validItem
    },
    can_save() {
      return this.editMode
        ? this.fileLink.id && this.fileLink.url
        : this.items.length > 0
    },
    title() {
      return this.editMode ? 'Edit Backlink' : 'New Backlink'
    },
    btn_label() {
      return this.editMode ? 'Update' : 'Save'
    },
    editMode() {
      return !!this.item
    },
    form_disabled() {
      if (this.btnloading) return true
      return this.editMode ? false : this.items.length === this.max_limit
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    },
    clearAll() {
      if (this.$refs.form) this.$refs.form.reset()
      this.fileLink = {
        id: null,
        url: null,
        title: null,
        site_id: null,
        strategy_id: null,
        domain_authority: null,
        status: 'under_review',
        tags: []
      }
      this.validItem = false
    },
    addItem() {
      this.items.push(_cloneDeep(this.fileLink))
      this.$nextTick(() => {
        this.clearAll()
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    addTag() {
      if (this.tag && this.tag.length > 2) {
        this.fileLink.tags.push(this.tag)
        this.tag = null
      }
    },
    removeTag(index) {
      this.fileLink.tags.splice(index, 1)
    },
    getSrc(url) {
      let tag = document.createElement('a')
      tag.href = url
      return `https://www.google.com/s2/favicons?domain=${tag.hostname}&alt=feed`
    },
    save() {
      this.btnloading = true
      if (!this.editMode && this.items.length > 0) {
        let payload = this.items.map((item) => {
          return {
            id: item.id,
            title: item.title,
            publish_url: item.url,
            strategy: item.strategy_id,
            site: item.site_id,
            domain_authority: item.domain_authority,
            status: item.status,
            tags: item.tags
          }
        })
        this.$emit('save', { links: payload })
      } else if (this.editMode && this.fileLink) {
        let payload = {
          id: this.fileLink.id,
          title: this.fileLink.title,
          publish_url: this.fileLink.url,
          strategy: this.fileLink.strategy_id,
          site: this.fileLink.site_id,
          domain_authority: this.fileLink.domain_authority,
          status: this.fileLink.status,
          tags: this.fileLink.tags
        }
        this.$emit('update', payload)
      }
    }
  }
}
</script>

<style scoped></style>
