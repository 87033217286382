<template>
  <v-card elevation="0" class="rounded-10 pb-5" color="transparent" min-height="500">
    <!-- <v-tabs
      class="tabs mb-1 border-color--grey"
      height="50px"
      grow
      v-model="active_tab"
      :show-arrows="mdAndDown"
      dense
      centered
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="tab.name"
        @click="changeTab(tab.view)"
        exact
        :class="`tab-${index}`"
        active-class="primary"
        class="text-none"
      >
        <div>
          <v-icon :class="{ 'white--text': active_tab === index }" left>
            {{ tab.icon }}
          </v-icon>
          <span :class="{ 'white--text': active_tab === index }">
            {{ tab.name }}
          </span>
        </div>
      </v-tab>
    </v-tabs> -->
    <Reports :project-id="id"></Reports>
    <!-- <Reports :project-id="id" v-if="view === `reports`"></Reports> -->
    <!-- <SEOBacklink
      :project-id="id"
      v-else-if="view === `seo-backlinks`"
    ></SEOBacklink> -->
  </v-card>
</template>

<script>
import Reports from "./Reports/Reports.vue";
import SEOBacklink from "./SEO-Backlinks/SEOBacklink.vue";

export default {
  name: "Reporting",
  components: {
    SEOBacklink,
    Reports,
  },
  props: {
    id: [Number, String], //from route prop
  },

  data() {
    return {
      active_tab: "reports",
      view: "reports",
      tabs: [
        {
          name: "Reports",
          view: `reports`,
          icon: "mdi-alpha-r-box-outline",
        },
        {
          name: "Backlinks",
          view: `seo-backlinks`,
          icon: "mdi-alpha-b-circle",
        },
      ],
      others: [],
    };
  },
  created() {
    if (this.$route.params.view) {
      this.view = this.$route.params.view;
      let index = this.tabs.findIndex((i) => i.view === this.view);
      this.active_tab = ~index ? index : 0;
    }
  },

  methods: {
    changeTab(tab) {
      this.view = tab;
      this.$router.push({
        name: "project-preview-reports",
        params: {
          id: this.id,
          view: this.view,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tab-0 {
  border-top-left-radius: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.tab-1 {
  border-top-right-radius: 10px;
  border-bottom: 1px solid #e0e0e0;
}
</style>