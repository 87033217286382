import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'

export const seo_mixin = {
    data: () => ({
        submitting: false,
        strategy_list: [],
        site_list: [],
        activeStrategy: null,
        activeSite: null,
        linkRules: [
            (v) =>
            (v && /(https?:\/\/[^\s]+)/.test(v)) ||
            'Link should be in valid URI string'
        ],
        numberRules: [
            (v) => (v && parseInt(v) > 100) || 'Maximum value is 100',
            (v) => (v && parseInt(v) < 0) || 'Minimum value is 0'
        ],
        nameRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length < 60) || 'Maximum characters is 60',
            (v) => (v && v.length > 2) || 'Minimum characters is 3'
        ],
        tagRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length < 100) || 'Maximum characters is 100',
            (v) => (v && v.length > 2) || 'Minimum characters is 3'
        ],
        site: {
            name: null,
            url: null
        },
        strategy: null,
        siteForm: false,
        strategyForm: false
    }),
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        getStrategies() {
            request.get(`api/seo/strategies`).then(({ data }) => {
                this.strategy_list = data
            })
        },
        handleSaveStrategy() {
            this.submitting = true
            let payload = { name: this.strategy }
            request
                .post(`api/seo/strategies`, payload)
                .then(({ data }) => {
                    this.strategy_list.push(data)
                    this.appSnackbar('New strategy added')
                })
                .finally(() => {
                    if (this.$refs.strategyForm) this.$refs.strategyForm.reset()
                    this.submitting = false
                })
        },
        handleDeleteStrategy() {
            request
                .delete(`api/seo/strategies/${this.activeStrategy.id}`)
                .then(({ data }) => {
                    let index = this.strategy_list.findIndex(
                        (item) => item.id === this.activeStrategy.id
                    )
                    if (~index) {
                        this.strategy_list.splice(index, 1)
                        this.appSnackbar('Strategy deleted')
                    }
                })
        },
        handleUpdateSites(payload) {
            //todo
        },
        handleUpdateStrategies(payload) {
            //todo
        },
        getSites() {
            request.get(`api/seo/sites`).then(({ data }) => {
                this.site_list = data
            })
        },
        handleSaveSites() {
            this.submitting = true
            let payload = this.site
            request
                .post(`api/seo/sites`, payload)
                .then(({ data }) => {
                    this.site_list.push(data)
                    this.appSnackbar('New site added')
                })
                .finally(() => {
                    if (this.$refs.siteForm) this.$refs.siteForm.reset()
                    this.submitting = false
                })
        },
        handleDeleteSite() {
            request.delete(`api/seo/site/${this.activeSite.id}`).then(({ data }) => {
                let index = this.site_list.findIndex(
                    (item) => item.id === this.activeSite.id
                )
                if (~index) {
                    this.site_list.splice(index, 1)
                    this.appSnackbar('Site deleted')
                }
            })
        },
        handleUploads(files, cb) {
            let error = []
            files.forEach((file) => {
                if (file.size <= this.settings.fileSizeMaxUploadLimit) {
                    let formData = new FormData()
                    formData.append('file', file)
                    request.post(`api2/attachments/dropzone`, formData).then(
                        ({ data }) => {
                            if (typeof cb === 'function') cb(data)
                        },
                        (error) => {
                            console.log(error)
                        }
                    )
                } else {
                    error.push(`${file.name} exceed allowed max filesize limit`)
                }
            })
            this.$nextTick(() => {
                if (error.length > 0) {
                    this.appSnackbar(error.join('<br>'), 'error')
                }
            })
        },
        deleteAttachment(attachment, index) {
            request
                .delete(`api2/attachments/delete/${attachment.uuid}`)
                .then(({ data }) => {
                    this.item.attachments.splice(index, 1)
                    this.$emit('deleted-attachment', attachment)
                })
        },
        viewAttachment(item) {
            this.$event.$emit('view-attachment', item)
        }
    }
}