<template>
  <v-card flat class="fullwidth" v-bind="$attrs" v-if="items.length">
    <v-card-text
      class="d-flex fullwidth"
      v-for="section in items"
      :key="section.id"
    >
      <v-card
        flat
        outline
        class="rounded-tl-10 fullwidth border-1 border-color--primary rounded-tr-10"
        v-if="section.tag === 'FormSection'"
      >
        <v-card-title
          class="primary py-1 rounded-tl-10 rounded-tr-10"
        ></v-card-title>
        <v-card-text>
          <v-row
            align="center"
            justify="space-between"
            class="mx-md-2 my-1"
            v-for="form in section.value"
            :key="form.id"
          >
            <template v-if="form.tag.includes('Form')">
              <v-col
                :cols="mdAndUp ? 3 : 12"
                class="d-flex align-center justify-start':'"
              >
                <label class="fw-700 fs-15 primary--text">
                  {{ form.label }}:
                </label>
              </v-col>
              <v-col
                :cols="mdAndUp ? 9 : 12"
                class="d-flex align-center justify-start"
              >
                <AnswerDataViewer :item="form"></AnswerDataViewer>
              </v-col>
            </template>
            <template v-else-if="form.tag === 'DisplayImage'">
              <ShowImage :item="form"></ShowImage>
            </template>
            <template v-else-if="form.tag === 'DisplayVideo'">
              <ShowVideo :item="form"></ShowVideo>
            </template>
            <template v-else-if="form.tag === 'DisplayText'">
              <ShowText :item="form"></ShowText>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import ShowImage from '../../../Forms-Online/components/ShowImage.vue'
import ShowText from '../../../Forms-Online/components/ShowText.vue'
import ShowVideo from '../../../Forms-Online/components/ShowVideo.vue'
import AnswerDataViewer from '../Answer/AnswerDataViewer.vue'

export default {
  inheritAttrs: false,
  name: 'Response',
  components: {
    AnswerDataViewer,
    ShowImage,
    ShowVideo,
    ShowText
  },
  props: {
    items: Array,
    attachments: Array
  },
  methods: {
    openLink(link) {
      window.open(link, 'blank')
    }
  }
}
</script>

<style scoped></style>
