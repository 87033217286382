<template>
  <v-menu bottom right offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            class="text-none px-5"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            New Report
            <v-spacer />
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Add Report</span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('new-via-link')">
        <v-list-item-title class="subtitle-2 primary--text">
          <v-icon color="primary" left>mdi-plus-circle-outline</v-icon>
          Add new report via link
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('new-via-template')">
        <v-list-item-title class="subtitle-2 primary--text">
          <v-icon color="primary" left>mdi-plus-circle-outline</v-icon>
          Add new report via template
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('new-via-preset')">
        <v-list-item-title class="subtitle-2 primary--text">
          <v-icon color="primary" left>mdi-plus-circle-outline</v-icon>
          Add new reports from presets
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AddReportMenu",
};
</script>
<style>
</style>