<template>
  <div class="crop-image">
    <div class="image-croppppie" />
  </div>
</template>

<script>
import Croppie from "croppie";
import "croppie/croppie.css";

export default {
  props: {
    image: { type: [String, Object] },
    options: { type: [String, Object] },
    result: { type: [String, Object] },
  },
  data: () => ({
    croppie: null,
  }),

  mounted() {
    const image = document.querySelector(".image-croppppie");
    this.croppie = new Croppie(image, this.options);
    this.croppie.bind(this.image);
  },

  methods: {
    get_result() {
      return this.croppie.result(this.result);
    },
    clear_component() {
      this.croppie.destroy();
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
};
</script>
