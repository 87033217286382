import request from '@/services/axios_instance'

export default {
    deleteReport(reportId) {
        return request.delete(`api/report/${reportId}`)
    },
    updateReport(reportId, payload) {
        return request.put(`api/report/${reportId}`, payload)
    },
    saveReportViaTemplate(payload) {
        return request.post(`api/report/via-template`, payload)
    },
    updateReportViaTemplate(id, payload) {
        return request.put(`api/report/${id}/via-template`, payload)
    },
    get_reports(project_id) {
        if (project_id) {
            return request.get(`api/projects/${project_id}/reports`)
        } else {
            return request.get(`api/reports`)
        }
    },
    get_more_reports(next_url) {
        return request.get(next_url)
    },
    setIcon(payload) {
        return request.post(`api/report/set-icon`, payload)
    },
    getPresets() {
        return request.get(`api/report/presets`)
    },
    deletePreset(id) {
        return request.delete(`api/report/presets/${id}`)
    },
    bulkAddReports(payload) {
        return request.post(`api/report/via-presets`, payload)
    },
    save_preset(payload) {
        return request.post(`api/report/presets`, payload)
    }
}
