<template>
  <v-card class="mx-auto" flat v-if="activeReport">
    <v-list-item>
      <v-list-item-content>
        <v-alert prominent outlined type="error">
          <v-row align="center">
            <v-col class="grow">
              We are unable to completely load the site content because of the
              website restrictions.
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="primary"
                :href="activeReport.url"
                target="_blank"
                class="text-none"
              >
                Go to Site
                <v-icon right>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-list-item-content>
    </v-list-item>
    <v-card-title class="text-center">
      <v-spacer />
      <span>{{ activeReport.props.title }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="text--primary text-center">
      <v-avatar size="100%" tile>
        <img
          class="white--text align-center mx-auto"
          v-lazy="activeReport.props.image"
        />
      </v-avatar>
      <div class="subtitle">
        {{ activeReport.props.description }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        :href="activeReport.url"
        target="_blank"
        class="text-none"
      >
        Go to Site
        <v-icon right>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CrawlerView",
  props: { activeReport: Object },
};
</script>

<style>
</style>