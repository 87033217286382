// this file required fetch_services and preset_mixins

import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'

export const report_mixins = {
    data: () => ({
        icon_dialog: false,
        activeReport: null,
        add_edit_via_url: false,
        add_edit_via_template: false,
        add_via_preset: false,
        create_preset_dialog: false
    }),
    methods: {
        open_icon_dialog(status, item) {
            this.activeReport = item
            this.icon_dialog = status
        },
        edit_report(report) {
            this.activeReport = report
            if (report.url === 'template') {
                this.add_edit_via_template = true
            } else {
                this.add_edit_via_url = true
            }
        },
        add_edit_report_via_url(show, item) {
            this.activeReport = item
            this.add_edit_via_url = show
        },
        add_edit_report_via_template(show, item) {
            this.activeReport = item
            this.add_edit_via_template = show
        },
        add_edit_report_via_preset(show, item) {
            this.activeReport = item
            this.add_via_preset = show
        },
        handleUpdateIcon(payload) {
            request
                .put(`api/report/${this.activeReport.id}/set-icon`, payload)
                .then(({ data }) => {
                    this.activeItem = data
                    this.open_icon_dialog(false, data)
                    this.appSnackbar('Icon updated')
                })
        },
        handleDeleteReport() {
            this.deleteItem(
                `api/report/${this.activeItem.id}`,
                'Report deleted',
                () => {
                    this.action_confirm_dialog(
                        false,
                        this.items.length ? this.items[0] : null
                    )
                    this.activeReport = null
                },
                null
            )
        },
        handleSaveReportViaURL(payload) {
            this.addItem(`api/report`, payload, 'Report added', (data) => {
                this.add_edit_via_url = false
                this.activeItem = data
                this.activeReport = data
            })
        },
        handleUpdateReportViaURL(payload) {
            this.updateItem(
                `api/report/${this.activeItem.id}`,
                payload,
                'Report updated',
                (data) => {
                    this.add_edit_via_url = false
                    this.activeItem = data
                    this.activeReport = data
                }
            )
        },
        handleSaveReportViaTemplate(payload) {
            this.addItem(
                `api/report/via-template`,
                payload,
                'Report added',
                (data) => {
                    this.add_edit_via_template = false
                    this.activeItem = data
                    this.activeReport = data
                }
            )
        },
        handleUpdateReportViaTemplate(payload) {
            this.updateItem(
                `api/report/${this.activeItem.id}/via-template`,
                payload,
                'Report updated',
                (data) => {
                    this.add_edit_via_url = false
                    this.activeItem = data
                    this.activeReport = data
                }
            )
        },
        handleSaveReportViaPreset(payload) {
            this.create_reports_from_presets(payload, (data) => {
                this.items.push(...data)
                this.add_via_preset = false
                this.activeItem = data.length ? data[0] : null
                this.activeReport = data.length ? data[0] : null
            })
        },
        handleSaveNewPreset(payload) {
            // console.log(payload)
            this.save_new_presets(payload, (data) => {
                this.create_preset_dialog = false
            })
        }
    }
}