<template>
  <CustomDialog
    :open.sync="open"
    title="Add Reports From Presets"
    @button1="clear_and_close"
    @button2="save"
    button2-text="Save Reports"
    :main-btn-disabled="!can_save"
    max-width="700"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <label class="subtitle-2 primary--text">
            Select Preset Template
          </label>
          <v-select
            :items="presets"
            dense
            :rules="requiredRules"
            outlined
            hide-details="auto"
            class="mb-1"
            item-text="name"
            prepend-inner-icon="mdi-clipboard-outline"
            return-object
            v-model="report_preset"
          >
            <template v-slot:no-data>
              <v-alert class="mx-2" color="cyan" colored-border>
                No report preset yet.
              </v-alert>
            </template>
            <template v-slot:append-outer>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="$emit('create-preset')"
                    v-bind="attrs"
                    v-on="on"
                    class="align-self-center"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Create New Report Preset</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-list dense two-line v-if="fillables && fillables.length > 0">
            <v-list-item v-for="(item, index) in fillables" :key="index">
              <!--<v-list-item-icon>
                            <v-img  :lazy-src="settings.loader" v-if="item.icon" :src="item.icon" contain></v-img>
                            <v-icon v-else>mdi-image-outline</v-icon>
                        </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    v-if="item.selected"
                    prepend-inner-icon="mdi-link"
                    v-model="item.link"
                    dense
                    outlined
                    clear-icon="mdi-close-circle-outline"
                    clearable
                    hide-details="auto"
                    :placeholder="`Enter URL for ${item.title}`"
                    :rules="linkRules"
                  />
                  <v-text-field
                    v-else
                    prepend-inner-icon="mdi-link"
                    v-model="item.link"
                    dense
                    :rules="[urlRule()]"
                    outlined
                    clear-icon="mdi-close-circle-outline"
                    clearable
                    hide-details="auto"
                    :placeholder="`Enter URL for ${item.title}`"
                    disabled
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  v-model="item.selected"
                  off-icon="mdi-sticker-outline"
                  on-icon="mdi-sticker-check-outline"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <Empty
            v-else
            headline="Select from preset"
            icon="mdi-archive-arrow-up-outline"
          />
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ReportViaPresets',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    presets: Array,
    projectId: [Number, String]
  },
  data: () => ({
    open: false,
    valid: false,
    linkRules: [
      (v) =>
        (v && /(https?:\/\/[^\s]+)/.test(v)) ||
        'Link should be in valid URI string'
    ],
    fillables: [],
    report_preset: null
  }),
  watch: {
    value(val) {
      this.open = val
      if (val) {
        this.report_preset = null
        this.fillables = []
      }
    },
    open(val) {
      this.$emit('input', val)
    },
    report_preset: {
      handler: function (val) {
        this.fillables = val && val.data ? _cloneDeep(val.data) : []
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    can_save() {
      let filter = this.fillables.filter((i) => i.selected === true)
      if (filter.length === 0) return false
      for (let i = 0; i < filter.length; i++) {
        if (
          filter[i].hasOwnProperty('link') &&
          (!filter[i].link || !/(https?:\/\/[^\s]+)/.test(filter[i].link))
        ) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    clear_and_close() {
      this.open = false
    },
    save() {
      let selected = this.fillables.filter((i) => i.selected === true)
      this.$emit('save', { selected: selected, project_id: this.projectId })
    }
  }
}
</script>

<style scoped></style>
