import apiTo from "./api";

export const presets_mixin = {
    data: () => ({
        reports: [],
        preset_reports: [],
        preset_dialog: false,
        preset_set_dialog: false
    }),
    created() {
        this.loadPresets()
    },
    methods: {
        loadPresets() {
            apiTo.getPresets().then(({ data }) => {
                this.preset_reports = data
            })
        },
        add_to_presets(data) {
            this.preset_reports.push(data)
        },
        delete_preset(id) {
            apiTo.deletePreset(id)
                .then(({ data }) => {
                    let index = this.preset_reports.findIndex(i => i.id === id)
                    if (~index) {
                        this.preset_reports.splice(index, 1)
                        this.appSnackbar( 'Preset deleted!')
                    }
                })
        },
        create_reports_from_presets(payload, cb) {
            apiTo.bulkAddReports(payload)
                .then(({ data }) => {
                    this.reports.push(...data)
                    this.preset_set_dialog = false
                    this.appSnackbar( 'Reports created')
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
        },
        save_new_presets(payload, cb) {
            apiTo.save_preset(payload)
                .then(({ data }) => {
                    this.add_to_presets(data)
                    this.appSnackbar( 'Preset created')
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                });
        }
    }
}