<template>
  <v-menu bottom right offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            class="text-none px-5 mx-1"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            Settings
            <v-spacer />
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Settings</span>
      </v-tooltip>
    </template>
    <v-card flat max-width="400" min-width="300">
      <v-card-title class="py-2">
        <span class="subtitle-1 primary--text">Preset Reports</span>
        <v-spacer />
        <v-btn small depressed color="primary" @click="$emit('new-preset')">
          <v-icon small left> mdi-plus-circle-outline </v-icon> Create
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-list dense v-if="presets.length">
        <v-list-item v-for="preset in presets" :key="preset.id">
          <v-list-item-subtitle>
            {{ preset.name | ucwords }}
          </v-list-item-subtitle>
          <v-list-item-icon>
            <v-icon @click="delete_preset(preset.id)">
              mdi-delete-circle-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <Empty v-else headline="No preset reports yet!" />
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "PresetMenu",
  props: {
    presets: Array,
  },
};
</script>

<style>
</style>