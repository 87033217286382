<template>
  <CustomDialog
    :open.sync="open"
    title="Add Preset Report Template"
    @button1="clear_and_close"
    @button2="save"
     
    extra-body-class="pa-0"
    button2-text="Save"
    :main-btn-disabled="!can_save"
    max-width="600"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <label class="subtitle-2 primary--text">Preset Name * </label>
              <v-text-field
                dense
                hide-details="auto"
                placeholder="Untitled Preset"
                clearable
                clear-icon="mdi-close-circle-outline"
                class="fullwidth"
                outlined
                :counter="60"
                v-model.trim="preset_name"
                prepend-inner-icon="mdi-alpha-t-box-outline"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            v-for="(each, index) in presets"
            :key="index"
            align="center"
            justify="start"
            class="border-1 mb-2 pa-3 rounded-10 border-color--grey"
          >
            <v-col :cols="12">
              <label class="subtitle-2 primary--text">Title * </label>
              <v-text-field
                dense
                outlined
                placeholder="Untitled Link"
                filled
                hide-details="auto"
                :value="each.title"
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <label class="subtitle-2 primary--text">
                Default Link (optional)
              </label>
              <v-text-field
                dense
                placeholder="https://"
                readonly
                outlined
                hide-details="auto"
                :value="each.link"
              />
            </v-col>
            <v-btn
              color="primary"
              class="text-none my-1"
              @click.stop="remove_from_presets(index)"
            >
              <v-icon left>mdi-minus-circle-outline</v-icon>
              Remove
            </v-btn>
          </v-row>
          <v-form v-model="valid">
            <v-row
              align="center"
              justify="end"
              dense
              class="border-1 pa-3 rounded-10 border-color--grey"
            >
              <v-col :cols="12">
                <label class="subtitle-2 primary--text">Title * </label>
                <v-text-field
                  dense
                  placeholder="Untitled Link"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  outlined
                  hide-details="auto"
                  v-model.trim="title"
                  prepend-inner-icon="text_fields"
                  :rules="requiredRules"
                />
              </v-col>
              <v-col :cols="12">
                <label class="subtitle-2 primary--text">
                  Default Link (optional)
                </label>
                <v-text-field
                  dense
                  placeholder="https://"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  class="fullwidth mt-1"
                  outlined
                  hide-details="auto"
                  pattern="https://.*"
                  type="url"
                  v-model="link"
                  prepend-inner-icon="link"
                  :rules="[urlRule()]"
                />
              </v-col>
              <v-btn
                min-width="120"
                color="primary"
                class="text-none my-2"
                :disabled="!can_add"
                @click.stop="add_to_presets"
              >
                <v-icon left>mdi-plus-circle-outline</v-icon>
                Add
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import request from "@/services/axios_instance";

export default {
  name: "CreatePresetDialog",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    open: false,
    valid: false,
    presets: [],
    preset_name: null,
    title: null,
    link: null,
  }),
  watch: {
    value(val) {
      this.open = val;
      if (val) {
        this.presets = [];
        this.title = this.link = this.preset_name = null;
      }
    },
    open(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    can_save() {
      return !(!this.presets || this.presets.length === 0 || !this.preset_name);
    },
    can_add() {
      return this.valid;
    },
  },
  methods: {
    clear_and_close() {
      this.open = false;
    },
    save() {
      this.$emit("save", {
        data: this.presets,
        name: this.preset_name,
      });
    },
    add_to_presets() {
      let payload = {
        title: this.title,
        link: this.link,
        selected: true,
        icon: null,
      };
      this.presets.push(payload);
      this.$nextTick(() => {
        this.title = null;
        this.link = null;
      });
    },
    remove_from_presets(index) {
      this.presets.splice(index, 1);
    },
  },
};
</script>

<style scoped>
</style>