<template>
  <div>
    <v-sheet
      color="transparent"
      dense
      v-if="type === 'file_upload'"
      v-bind="$attrs"
    >
      <v-sheet
        color="transparent"
        class="ma-2 d-flex align-center justify-start rounded-5"
        v-if="item.value"
      >
        <div class="d-flex align-center transparent">
          <v-avatar size="50" @click="$emit('view-file')" tile>
            <v-img
              contain
              v-if="is_image(item.value.filetype)"
              :src="item.value.url_inline"
            >
              <template v-slot:placeholder>
                <v-icon x-large>mdi-image</v-icon>
              </template>
            </v-img>
            <span
              v-else
              class="v-icon fiv-icon fiv-sqo fiv-size-sm"
              :class="[`fiv-icon-` + get_extension(item.value.filename)]"
            ></span>
          </v-avatar>
        </div>
        <div class="align-self-center ml-1 transparent">
          <h5
            class="d-block fullwidth primary--text subtitle-2"
            :title="item.value.filename"
          >
            {{ item.value.filename | trunc_mid(15, 7) }}
          </h5>
          <div class="d-block fullwidth">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click="openLink(`${item.value.url}?disposition=attachment`)"
                  left
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-cloud-download-outline
                </v-icon>
              </template>
              <span>Download file</span>
            </v-tooltip>
            <span class="ml-2">{{ item.value.filesize | bytesToSize }}</span>
          </div>
        </div>
      </v-sheet>
      <div v-else class="pa-2">No uploaded file</div>
    </v-sheet>
    <v-sheet v-else-if="item.value" color="transparent" dense>
      <v-chip label class="border-0 fw-500" color="transparent" v-bind="$attrs">
        {{
          typeof item.value === "object" ? item.value.join(", ") : item.value
        }}
      </v-chip>
    </v-sheet>
    <div v-bind="$attrs" v-else>No answer</div>
  </div>
</template>

<script>
export default {
  components: {
    //
  },
  inheritAttrs: false,
  name: "AnswerDataViewer",
  props: {
    item: { type: Object },
  },
  computed: {
    type() {
      return this.item ? this.item.type : null;
    },
  },
  methods: {
    is_image(mimeType) {
      return !!(typeof mimeType !== "undefined" && mimeType.includes("image"));
    },
    openLink(link) {
      window.open(link, "blank");
    },
  },
};
</script>

<style>
</style>